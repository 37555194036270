<template>
  <div :class="$style.newOrder">
    <Title text="Создание занятия" position="right" />
    <div :class="$style.newOrder__items">
      <div :class="$style.newOrder__item">
        <p>Покупатель</p>
        <div :class="$style.newOrder__user" @click="showInputSearch = !showInputSearch">
          {{
            user
              ? '[' +
                user.id +
                '] ' +
                user.attributes.firstName +
                ' ' +
                user.attributes.lastName
              : 'Найти'
          }}
        </div>
        <Input
          v-if="showInputSearch"
          v-model="searchUser"
          :class="$style.newOrder__user_id"
          placeholder="Поиск по ID"
        />
        <div
          v-if="showInputSearch && showUsersList && showUsersList.length"
          :class="$style.newOrder__users"
        >
          <div
            v-for="user in showUsersList"
            :key="user.id"
            :class="$style.newOrder__users_item"
            @click="selectUser(user)"
          >
            [{{ user.id }}] {{ user.attributes.firstName }} {{ user.attributes.lastName }}
          </div>
        </div>
      </div>
      <div :class="$style.newOrder__item">
        <p>Класс ученика</p>
        <DefaultSelect
          v-model="sendData.studentGrade"
          :items="grades"
          placeholder="Выбрать"
          @input="(val) => (sendData.studentGrade = val.id)"
        />
      </div>
    </div>
    <div :class="$style.newOrder__items">
      <div :class="$style.newOrder__item" style="width: auto; margin: 0.938rem 2.75rem 0 0">
        <p>Пробное</p>
        <DefaultSwitch
          v-model="sendData.isTrial"
          :rounded="true"
          @change="(value) => setIsTrial(value)"
        />
      </div>
    </div>
    <div :class="$style.newOrder__items">
      <div :class="$style.newOrder__item">
        <p>Предмет</p>
        <DefaultSelect
          v-model="sendData.subjectId"
          :items="orderSubjects"
          placeholder="Выбрать"
          @input="(val) => (sendData.subjectId = val.id)"
        />
      </div>
      <div :class="$style.newOrder__item">
        <p>Длительность</p>
        <DefaultSelect
          v-model="sendData.durationId"
          :items="orderDurations"
          :disabled="sendData.isTrial"
          placeholder="Выбрать"
          @input="(val) => (sendData.durationId = val.id)"
        />
      </div>
      <div :class="$style.newOrder__item">
        <p>Что будем делать?</p>
        <DefaultSelect
          v-model="sendData.lessonTypeId"
          :items="orderLessonTypeList"
          placeholder="Выбрать"
          @input="(val) => (sendData.lessonTypeId = val.id)"
        />
      </div>
    </div>

    <div :class="$style.newOrder__items">
      <textarea
        v-model="sendData.comment"
        :class="$style.newOrder__item_comment"
        cols="81"
        rows="5"
        placeholder="Комментарий для преподавателя"
      />
    </div>
    <div :class="$style.newOrder__items">
      <div :class="$style.newOrder__item">
        <p>Тип занятия</p>
        <DefaultSelect
          v-model="sendData.typeId"
          :items="orderType"
          placeholder="Выбрать"
          :disabled="!user"
          @input="(val) => changeType(val)"
        />
      </div>
      <div v-if="sendData.typeId === 2" :class="$style.newOrder__item">
        <p>Дата и время занятия</p>
        <date-picker
          v-model="sendData.datetime"
          :class="$style.newOrder__datepicker"
          :lang="lang"
          valueType="format"
          type="datetime"
          placeholer="Дата"
          style="width: 100%"
          :showTimeHeader="true"
          :disabled-date="(date) => disabledDates(date)"
        ></date-picker>
      </div>
    </div>

    <div v-if="sendData.typeId === 3" :class="$style.newOrder__items">
      <div :class="$style.newOrder__periodicSettings">
        <div :class="$style.lessonCount">
          <p :class="$style.newOrder__subtitle">Количество занятий:</p>
          <Input
            v-model="lessonsCount"
            :class="$style.input"
            mask="##"
            placeholder="0"
            type="primary"
            @input="validateLessonsCount"
          />
        </div>

        <div :class="$style.weekdays">
          <p :class="$style.newOrder__subtitle">Периодичность занятий:</p>
          <PeriodWeekdays v-model="weekdaysPattern" @set="validateWeekdayPattern" />
        </div>
      </div>

      <div :class="$style.newOrder__date">
        <div :class="$style.newOrder__date__wrapper">
          <p :class="$style.newOrder__subtitle">Дата начала занятия:</p>
          <date-picker
            v-model="sendData.date"
            :class="$style.newOrder__date__date"
            :lang="lang"
            :clearable="false"
            :editable="false"
            :disabled-date="disabledDates"
            type="date"
            placeholder="Выберите дату"
            :formatter="momentFormat"
            @input="validation(sendData.date, 'date', errors)"
          />
        </div>

        <div :class="$style.newOrder__date__wrapper">
          <p :class="$style.newOrder__subtitle">Время начала занятия:</p>
          <div :class="$style.newOrder__date__time">
            <InputNumber
              v-model="sendData.hours"
              type="secondary"
              :maxlength="2"
              placeholder="--"
              :error="errors.hours && !firstFill"
              @input="validation(sendData.hours, 'hours', errors)"
            />
            <span>:</span>
            <InputNumber
              v-model="sendData.minutes"
              type="secondary"
              :maxlength="2"
              placeholder="--"
              :error="errors.minutes && !firstFill"
              @input="validation(sendData.minutes, 'minutes', errors)"
            />
          </div>
        </div>
      </div>
    </div>

    <Button :class="$style.newOrder__button" type="tertiary" @click="createOrder">
      Создать занятие
    </Button>
  </div>
</template>

<script>
import Input from '@/basic/Input'
import Title from '@/basic/Title'
import Button from '@/basic/Button'
import InputNumber from '@/basic/InputNumber'
import DefaultSelect from '@/basic/DefaultSelect'
import PeriodWeekdays from '@/components/NewOrderSchedule'

import { mapMutations } from 'vuex'

import usersApi from '@/api/users'
import ordersApi from '@/api/orders'
import DefaultSwitch from '../basic/DefaultSwitch'

import {
  OrderType,
  MSK_TIMEZOME,
  TeachingType,
  // LessonStatus,
  Roles
} from '@/application/constants'

import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import Moment from 'moment'

import 'vue2-datepicker/index.css'

export default {
  name: 'NewOrder',
  components: {
    Title,
    Input,
    Button,
    InputNumber,
    DefaultSelect,
    DefaultSwitch,
    DatePicker,
    PeriodWeekdays
  },
  data() {
    return {
      lang: ru,
      showInputSearch: false,
      showUsersList: null,
      timeout: null,
      searchUser: null,
      user: null,
      customer: null,
      orders: [],

      grades: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((e) => {
        return {
          id: e,
          name: e + ' класс'
        }
      }),

      orderSubjects: [],
      orderTeachingType: [],
      orderLessonType: [],
      durations: [],
      trialDuration: {},
      orderDurations: [],
      orderType: [],

      sendData: {
        orderTeachingTypeId: null,
        firstSession: false,
        secondSession: false,
        isTrial: false
      },

      orderDate: null,
      orderHours: null,
      orderMinutes: null,

      weekdaysPattern: [],
      lessonsCount: '',

      errors: {
        weekdays: true,
        lessonsCount: true,
        date: true,
        hours: true,
        minutes: true,
        studentGrade: true
      },
      momentFormat: {
        stringify: (date) => {
          return date ? Moment(date).format('DD.MM.YYYY') : ''
        }
      },

      firstFill: true,
      oralMediaTypes: [
        'video/webm',
        'video/mp4',
        'video/x-msvideo',
        'video/x-matroska',
        'audio/mpeg',
        'audio/x-wav',
        'audio/mp4'
      ],
      writtenMediaTypes: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
      isWrittenMediaTypes: false,
      limitSize: 100, // 100 MB,
      childrenAgeGroupId: null,

      oldOrderSpeechDisorders: [],
      oldOtherSpeechDisorder: [],
      oldOrderExpertConclusions: '',
      oldOtherExpertConclusion: '',
      oldChildrenAgeGroupId: null,

      orderSpeechDisorders: [],
      orderExpertConclusions: [],
      otherSpeechDisorder: '',
      otherExpertConclusion: '',

      updateData: {}
    }
  },
  watch: {
    searchUser() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        if (this.searchUser && this.searchUser.length) {
          this.searchUsers()
        }
      }, 500)
    }
  },
  computed: {
    orderLessonTypeList() {
      return this.orderLessonType.filter((e) => (this.sendData.isTrial ? e.id !== 1 : e))
    },

    isSingle() {
      return this.sendData.typeId === OrderType.SINGLE
    },
    isPeriodic() {
      return this.sendData.typeId === OrderType.PERIODIC
    }
  },
  async beforeMount() {
    const { data: orderInfo } = await ordersApi.getOrderInfo()
    const { durations, lessonsTypes, subjects, teachingTypes, types } = orderInfo.result

    this.orderSubjects = subjects
    this.orderTeachingType = teachingTypes.filter((e) => e.id === TeachingType.TEACHER)
    this.sendData.orderTeachingTypeId = TeachingType.TEACHER
    this.orderLessonType = lessonsTypes
    this.durations = durations.filter((e) => !e.isInner)
    this.trialDuration = durations.find((e) => e.duration === 30)
    this.orderDurations = durations.filter((e) => !e.isInner)
    this.orderType = types
  },
  methods: {
    ...mapMutations(['setOrderDetails']),
    setSpeechDisorders() {
      this.orderSpeechDisorders = this.speechDisorders
        .filter((s) => s.selected)
        .map((d) => ({
          speechDisorderId: d.id,
          customName: d.name
        }))
    },
    setOrderExpertConclusions() {
      this.orderExpertConclusions = this.expertOpinions
        .filter((e) => e.selected)
        .map((e) => ({ expertConclusionId: e.id, customName: e.name }))
    },
    addDuration(duration) {
      if (!this.orderDurations.some((el) => el.id === duration.id)) {
        this.orderDurations.push(duration)
      }
    },
    setIsTrial(value) {
      this.sendData.isTrial = value
      delete this.sendData.lessonTypeId
      if (value) {
        this.sendData.durationId = this.trialDuration.id
        this.orderDurations.push(this.trialDuration)
      } else {
        delete this.sendData.durationId
        this.orderDurations = this.durations.filter((e) => !e.isInner)
      }
    },
    validateWeekdayPattern(v) {
      this.errors.weekdays = !v.length
    },
    validateLessonsCount(v) {
      this.errors.lessonsCount = !v.length
    },
    validation(value, name, errors) {
      if (typeof value === 'string') {
        errors[name] = !(value.trim() !== '' && value.length >= 2)
      } else {
        value ? (errors[name] = false) : (errors[name] = true)
      }
    },
    disabledDates(date) {
      return Moment(date).isBefore(Moment().format('YYYY-MM-DD'))
    },
    changeType(val) {
      this.sendData.typeId = val.id
      this.clearOrderDatetime()
    },
    trialSwitch(value) {
      this.sendData.isTrial = value
      if (value) {
        this.sendData.durationId = 1
        this.orderDurations = this.durations.filter((e) =>
          this.sendData.isTrial ? e.id < 2 : e
        )
      } else {
        this.orderDurations = this.durations
      }
    },
    async searchUsers() {
      const { data } = await usersApi.getParent({
        filter: {
          id: +this.searchUser,
          roleId: [Roles.PARENT, Roles.STUDENT]
        }
      })
      this.showUsersList = data.result.rows
    },
    async selectUser(user) {
      this.user = user
      this.orders = []
      this.orderExpertConclusions = []
      this.orderSpeechDisorders = []
      this.childrenAgeGroupId = null

      // const { result: userInfo } = await usersApi.userInfo(this.user.id)
      // const groupId = userInfo.find((e) => e.groupId && e.userId === this.user.id)?.groupId
      // if (groupId) {
      //   this.orders = userInfo.find((e) => e.groupId && e.userId === this.user.id)?.order
      // }

      const customer = await this.getCustomer(user)
      this.customer = customer || user
      this.sendData.customerId = this.customer.id
      this.showInputSearch = false
      this.searchUser = null
      this.showUsersList = null
    },
    async getCustomer(user) {
      if (user.parentId) {
        const { data } = await usersApi.getParent({
          filter: {
            id: user.parentId
          }
        })
        return data.result.rows[0] || user
      } else {
        return user
      }
    },
    validOrder() {
      return (
        this.sendData.lessonTypeId &&
        this.sendData.durationId &&
        // this.sendData.studentGrade && // не обязательное поле
        this.sendData.subjectId &&
        this.sendData.customerId
      )
    },
    isValidForm(errors) {
      for (const key in errors) {
        if (errors[key]) {
          return false
        }
      }
      return true
    },
    getDatesByWeekdays() {
      const count = Number(this.lessonsCount)

      if (count <= 0) {
        throw new TypeError('lessonsCount must be greater than 0')
      }

      const schedule = []
      const pattern = this.weekdaysPattern
      const currDate = Moment(this.sendData.date)

      for (; schedule.length < count; currDate.add(1, 'd')) {
        if (pattern.includes(currDate.isoWeekday() - 1)) {
          schedule.push(currDate.clone())
        }
      }
      return schedule
    },
    clearOrderDatetime() {
      delete this.sendData.datetime
      delete this.sendData.date
      delete this.sendData.hours
      delete this.sendData.minutes
      this.lessonsCount = ''
      this.weekdaysPattern = []

      this.errors.date = true
      this.errors.hours = true
      this.errors.minutes = true
      this.errors.weekdays = true
      this.errors.lessonsCount = true
    },
    async createOrder() {
      const duration = this.orderDurations.find(
        (e) => e.id === this.sendData.durationId
      ).duration
      const { result, success } = (
        await ordersApi.checkCanCreateOrder({
          userId: this.customer.id,
          duration,
          lessonsCount: this.isPeriodic ? +this.lessonsCount : 1
        })
      ).data
      if (success || this.sendData.isTrial || this.customer.attributes.useUnlimitedTariff) {
        if (
          [OrderType.EXPRESS, OrderType.SINGLE, OrderType.TRIAL].includes(this.sendData.typeId)
        ) {
          this.errors.date = false
          this.errors.hours = false
          this.errors.minutes = false
        }

        if (!this.isPeriodic) {
          this.errors.weekdays = false
          this.errors.lessonsCount = false
        }
        if (this.sendData.studentGrade) {
          this.errors.studentGrade = false
        }
        if (!this.validOrder() || !this.isValidForm(this.errors)) {
          this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Ошибка', message: 'Заполните все поля!' }
          ])
          return
        }

        if (
          !this.sendData.isTrial &&
          !this.customer.attributes.useTrialTariff &&
          !this.customer.account?.minutes &&
          !this.customer.attributes.useUnlimitedTariff
        ) {
          this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Ошибка', message: 'У покупателя отсутствует тариф!' }
          ])
          return
        }

        const getDatetime = (date) => {
          // Делаем создание заказа по МСК
          // const currentTimezoneOffset = new Date().getTimezoneOffset() / 60
          // const resultOffset = this.user.attributes.timezone + currentTimezoneOffset

          return Moment(date)
            .set('hour', this.sendData.hours)
            .set('minute', this.sendData.minutes)
            .set('second', 0)
            .set('milliseconds', 0)
            .subtract(MSK_TIMEZOME, 'h')
            .toDate()
            .toISOString()
        }

        if (this.isSingle) {
          // Делаем создание заказа по МСК
          // const currentTimezoneOffset = new Date().getTimezoneOffset() / 60
          // const resultOffset = this.user.attributes.timezone + currentTimezoneOffset

          this.sendData.datetime = Moment(this.sendData.datetime)
            .set('milliseconds', 0)
            .subtract(MSK_TIMEZOME, 'h')
            .toDate()
            .toISOString()
        }

        if (this.isPeriodic) {
          this.sendData.schedule = this.getDatesByWeekdays()
          this.sendData.schedule = this.sendData.schedule.map((day) => {
            return { datetime: getDatetime(day) }
          })
        }

        const {
          durationId,
          comment,
          isTrial,
          studentGrade,
          lessonTypeId,
          subjectId,
          typeId,
          datetime,
          schedule
        } = this.sendData

        const serverData = {
          studentId: this.user.id,
          attributes: {
            lessonTypeId,
            studentGrade
          },
          datetime,
          schedule,
          typeId,
          subjectId,
          durationId,
          isTrial,
          comment
        }

        if (this.isPeriodic) {
          delete serverData.date
          delete serverData.datetime
        } else {
          delete serverData.schedule
        }

        try {
          const { result } = await ordersApi.createOrder(serverData)
          const orderDetails = this.isPeriodic ? result[0] : result
          await this.setOrderDetails(orderDetails)
          this.$router.push(`/orders/${orderDetails.id}`)
        } catch (e) {
          this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Ошибка', message: e?.response?.data?.result?.message }
          ])
        }
      } else {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка!', message: result.message }
        ])
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.newOrder {
  &__subtitle {
    margin: 0 0 0.5rem;
    font-size: 0.825rem;
    color: $pearl-light-gray;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    &__radio {
      width: 49%;
      flex-direction: column;
      display: inline-flex;
      margin-bottom: 0.625rem;
    }
    &__block {
      border: 1px solid $platinum;
      width: 49%;
      flex-direction: column;
      display: inline-flex;
      margin-bottom: 0.625rem;
      &_left {
        border-top-left-radius: 0.625rem;
        border-right: none;
        border-bottom-left-radius: 0.625rem;
      }
      &_right {
        border-left: none;
        border-top-right-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
      }
      &__item {
        display: inline-flex;
        align-items: center;
      }
    }
    &__files {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 5.25rem;
      margin: 2rem 0 1rem;
      background-color: rgba(217, 217, 217, 0.25);
      border-radius: 0.625rem;
      cursor: pointer;
      &__block {
        display: flex;
        align-items: center;
        span {
          margin: 0 0 0 1.25rem !important;
          font-weight: 400;
          font-size: 0.75rem;
          color: #a09f9f;
        }
      }
    }
  }

  &__item {
    width: 22rem;
    margin-right: 15px;

    &_text {
      display: block;
    }
    p {
      margin: 0 0 0.5rem;
      font-size: 0.825rem;
      color: $pearl-light-gray;
    }

    &_comment {
      width: 45rem;
      border: 1px solid #f0f0f0;
      resize: none;
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
      color: $gray;
    }
  }

  &__users {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #f0f0f0;
    cursor: pointer;
    border-top: none;

    &_item {
      padding: 5px;
      border-top: none;
    }
  }

  &__user {
    min-width: 22rem;
    height: 40px;
    cursor: pointer;
    border: 1px solid #f0f0f0;
    padding: 0.5rem 0.75rem;
    display: flex;
    align-content: center;
    align-items: center;

    &_id {
      width: 100%;
      margin-left: 0;
      border-radius: 0;
      border: 1px solid #f0f0f0;
      border-top: none;
    }
  }

  &__field {
    height: 39px;

    &_opt {
      padding: 5px;
    }
  }

  &__datepicker {
    width: 15.5rem !important;

    input {
      height: 2.5rem;
      padding: 0.5rem 0.75rem;
      border: 1px solid #f0f0f0;
      border-radius: 0;
      box-shadow: none;
      font-size: 1rem;
    }
  }

  &__periodicSettings {
    display: flex;
    width: 45%;
    margin-bottom: 1.25rem;

    .lessonCount {
      .input {
        width: 8.15rem;
        margin: 0;
        border-radius: 0;

        input {
          text-align: center;
        }
      }
    }
    .weekdays {
      flex: 1;
      margin-left: 1.25rem;
    }
  }

  &__date {
    display: flex;

    &__wrapper {
      &:last-child {
        width: 30%;
        margin: 0 0 0 2rem;
      }
    }

    &__date {
      width: 15.5rem !important;

      input {
        height: 2.5rem;
        padding: 0.5rem 0.75rem;
        border: 1px solid #f0f0f0;
        border-radius: 0;
        box-shadow: none;
        font-size: 1rem;
      }

      &_disabled {
        pointer-events: none;
      }
    }

    &__time {
      display: flex;

      span {
        margin: 0 1rem;
        font-size: 3.125rem;
        color: $pearl-light-gray;
      }

      input {
        font-size: 2.125rem;

        &::placeholder {
          font-size: 2.125rem;
        }
      }
    }
  }

  &__button {
    margin: 2rem 0 0;
  }
}
</style>
